import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import profile from "./slices/profileSlice";
import auth from "./slices/authSlice";
import settings from "./slices/settingSlice";
import language from "./slices/languageSlice";
const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    profile,
    language,
    auth,
    settings,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
