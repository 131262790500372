import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  // profileData: [],
  languageData:[]
};

export const languageSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setLanguageDetails: (state, action) => {
      // console.log(action.payload, "action.payloadaction.payload");
      state.loading = false;
      state.languageData = action.payload;
    },
  },
});

export const { setLanguageDetails } = languageSlice.actions;

export default languageSlice.reducer;
