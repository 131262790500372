import { apiRequest } from "utils/async/apiUtils";

export const languageList = async (
  params,
  setList = () => {},
  setLoaded = () => {},
  setCount = () => {}
) => {
  setLoaded(false);

  try {
    const res = await apiRequest("get", "/language/list", {
      queries: {
        ...params,
        sortQuery: `${params.sortType === "DESC" ? "-" : ""}${
          params.sortValue
        }`,
      },
    });

    const data = res.data;
    setList(data?.docs?.data);
    setCount(data?.docs?.metadata?.totalDocs);
    setLoaded(true);
  } catch (err) {
    setLoaded(true);
  }
};


export const languageStatusChange = async (ids = [], status) => {
  try {
    const res = await apiRequest("put", "language/status-change", {
      body: {
        ids: ids,
        status,
      },
    });
    return res;
  } catch (err) {
    throw new Error(err.message);
  }
};


export const getLanguageList = async (params) => {
  try {
    const res = await apiRequest("get", "/language/list", {
      queries: params,
    });
    return res;
  } catch (error) {
    return error;
  }
};
