import React, { useEffect, useState } from "react";
import { onBlankLayout } from "store/slices/themeSlice";
import { useDispatch, useSelector } from "react-redux";
import { loadProfileDetails } from "services/appServices/editProfileService";
import { setProfileDetails } from "store/slices/profileSlice";
import { getSettings } from "store/slices/settingSlice";
import { loadSettingDetails } from "services/appServices/siteSettingService";
import {
  getLanguageList,
  languageList,
} from "services/appServices/LanguageService";
import { setLanguageDetails } from "store/slices/languageSlice";

const AppRoute = ({
  component: Component,
  routeKey,
  blankLayout,
  ...props
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const isBlank = blankLayout ? true : false;
    dispatch(onBlankLayout(isBlank));
  }, [blankLayout]);

  // const profile = useSelector((state) => state?.profile?.pofileData);

  //Language List call and set on Redux
  useEffect(() => {
    getLanguageList({
      status: ["active"],
    }).then((response) => {
      // console.log(response, "responseresponse");
      dispatch(setLanguageDetails(response?.data?.docs?.data));
    });
  }, []);

  //profile details api call

  useEffect(() => {
    loadProfileDetails().then((res) => {
      dispatch(setProfileDetails(res?.data?.docs));
      // console.log(res?.data?.docs, "ssssssssfdgdfgdfghfgghgfhfghgf");
    });
  }, []);

  const settings = useSelector((state) => state?.settings?.data);
  useEffect(() => {
    loadSettingDetails().then((res) => {
      dispatch(getSettings(res?.data));
    });
  }, []);

  return <Component {...props} />;
};

export default AppRoute;
