import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "login-1",
    path: `${AUTH_PREFIX_PATH}/login-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-1")
    ),
  },
  {
    key: "login-2",
    path: `${AUTH_PREFIX_PATH}/login-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-2")
    ),
  },
  {
    key: "register-1",
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-1")
    ),
  },
  {
    key: "register-2",
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-2")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "error-page-1",
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  {
    key: "error-page-2",
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-2")),
  },
];

export const protectedRoutes = [
  {
    key: "admins.list",
    path: `${APP_PREFIX_PATH}/admins/list`,
    component: React.lazy(() => import("views/app-views/apps/admin/adminList")),
  },
  {
    key: "admins.add",
    path: `${APP_PREFIX_PATH}/admins/add`,
    component: React.lazy(() => import("views/app-views/apps/admin/adminAdd")),
  },
  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/edit-profile`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/EditProfile")
    ),
  },
  {
    key: "admins.add",
    path: `${APP_PREFIX_PATH}/admins/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/admin/admin-edit")
    ),
  },
  {
    key: "profile.password",
    path: `${APP_PREFIX_PATH}/change-password`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/ChangePassword")
    ),
  },

  // Usersection route
  {
    key: "users.list",
    path: `${APP_PREFIX_PATH}/users/list`,
    component: React.lazy(() => import("views/app-views/apps/user/userList")),
  },
  {
    key: "users.add",
    path: `${APP_PREFIX_PATH}/users/add`,
    component: React.lazy(() => import("views/app-views/apps/user/userAdd")),
  },
  {
    key: "users.edit",
    path: `${APP_PREFIX_PATH}/users/edit/:id`,
    component: React.lazy(() => import("views/app-views/apps/user/userEdit")),
  },
  {
    key: "users.details",
    path: `${APP_PREFIX_PATH}/user/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/user/userDetails/UserDetails")
    ),
  },

  // consultant section route
  {
    key: "consultant.list",
    path: `${APP_PREFIX_PATH}/consultant/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/consultant/consultantList")
    ),
  },
  {
    key: "consultant.add",
    path: `${APP_PREFIX_PATH}/consultant/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/consultant/consultantAdd")
    ),
  },
  {
    key: "consultant.edit",
    path: `${APP_PREFIX_PATH}/consultant/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/consultant/consultantEdit")
    ),
  },
  {
    key: "consultant.details",
    path: `${APP_PREFIX_PATH}/consultant/details/:id`,
    component: React.lazy(() =>
      import(
        "views/app-views/apps/consultant/consultantDetails/ConsultantDetails"
      )
    ),
  },

  //Enquery List section
  {
    key: "enquery.list",
    path: `${APP_PREFIX_PATH}/enquiry/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/enquery/enqueryList/index")
    ),
  },

  //profile route
  {
    key: "pages",
    path: `${APP_PREFIX_PATH}/pages`,
    component: React.lazy(() => import("views/app-views/pages")),
  },
  {
    key: "pages.profile",
    path: `${APP_PREFIX_PATH}/pages/profile`,
    component: React.lazy(() => import("views/app-views/pages/profile")),
  },
  {
    key: "pages.invoice",
    path: `${APP_PREFIX_PATH}/pages/invoice`,
    component: React.lazy(() => import("views/app-views/pages/invoice")),
  },
  {
    key: "pages.setting",
    path: `${APP_PREFIX_PATH}/pages/setting/*`,
    component: React.lazy(() => import("views/app-views/pages/setting")),
  },
  {
    key: "pages.user-list",
    path: `${APP_PREFIX_PATH}/pages/user-list`,
    component: React.lazy(() => import("views/app-views/pages/user-list")),
  },

  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() =>
      import("views/app-views/apps/dashboard/view-dashboard/")
    ),
  },

  // Site Setting
  {
    key: "site.settings.list",
    path: `${APP_PREFIX_PATH}/site-settings`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/SiteSetting")
    ),
  },

  // Pages
  {
    key: "pages.list",
    path: `${APP_PREFIX_PATH}/pages/list`,
    component: React.lazy(() => import("views/app-views/apps/Pages/PagesList")),
  },
  {
    key: "pages.list",
    path: `${APP_PREFIX_PATH}/page/add`,
    component: React.lazy(() => import("views/app-views/apps/Pages/PagesAdd")),
  },
  {
    key: "pages.list",
    path: `${APP_PREFIX_PATH}/page/edit/:id`,
    component: React.lazy(() => import("views/app-views/apps/Pages/PagesEdit")),
  },
  //Language List route
  {
    key: "language.list",
    path: `${APP_PREFIX_PATH}/language/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/language/languageList")
    ),
  },

  //Therapeutics List
  {
    key: "therapeutics.list",
    path: `${APP_PREFIX_PATH}/therapeutics/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/therapeutics/therapeuticsList")
    ),
  },
  {
    key: "therapeutics.add",
    path: `${APP_PREFIX_PATH}/therapeutics/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/therapeutics/therapeuticsForm")
    ),
  },
  {
    key: "therapeutics.edit",
    path: `${APP_PREFIX_PATH}/therapeutics/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/therapeutics/therapeuticsForm")
    ),
  },
  //Mental List
  {
    key: "mental.list",
    path: `${APP_PREFIX_PATH}/mental/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/mental/mentalList")
    ),
  },
  {
    key: "mental.add",
    path: `${APP_PREFIX_PATH}/mental/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/mental/mentalForm")
    ),
  },
  {
    key: "mental.edit",
    path: `${APP_PREFIX_PATH}/mental/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/mental/mentalForm")
    ),
  },
  //Psychosocial Problem List
  {
    key: "psychosocialProblem.list",
    path: `${APP_PREFIX_PATH}/psychosocialproblem/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/psychosocialProblem/psychosocialProblemList")
    ),
  },
  {
    key: "psychosocialProblem.add",
    path: `${APP_PREFIX_PATH}/psychosocialProblem/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/psychosocialProblem/psychosocialProblemForm")
    ),
  },
  {
    key: "psychosocialProblem.edit",
    path: `${APP_PREFIX_PATH}/psychosocialProblem/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/psychosocialProblem/psychosocialProblemForm")
    ),
  },
  //Others Topics List
  {
    key: "othersTopic.list",
    path: `${APP_PREFIX_PATH}/othersTopic/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/othersTopics/otherTopicsList")
    ),
  },
  {
    key: "othersTopic.add",
    path: `${APP_PREFIX_PATH}/othersTopic/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/othersTopics/otherTopicsForm")
    ),
  },
  {
    key: "othersTopic.edit",
    path: `${APP_PREFIX_PATH}/othersTopic/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/othersTopics/otherTopicsForm")
    ),
  },
  // Service List
  {
    key: "service.list",
    path: `${APP_PREFIX_PATH}/service/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/service/serviceList")
    ),
  },
  {
    key: "service.add",
    path: `${APP_PREFIX_PATH}/service/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/service/serviceForm")
    ),
  },
  {
    key: "service.edit",
    path: `${APP_PREFIX_PATH}/service/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/service/serviceForm")
    ),
  },
  // consultation List
  {
    key: "consultation.list",
    path: `${APP_PREFIX_PATH}/consultation/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/consultation/consultationList")
    ),
  },
  {
    key: "consultation.add",
    path: `${APP_PREFIX_PATH}/consultation/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/consultation/consultationForm")
    ),
  },
  {
    key: "consultation.edit",
    path: `${APP_PREFIX_PATH}/consultation/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/consultation/consultationForm")
    ),
  },

  //-------------Appointment------------------------
  {
    key: "appointment.list",
    path: `${APP_PREFIX_PATH}/appointment/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/appointment/appointmentList/index")
    ),
  },

  //--------------Wish List-------------------------
  {
    key: "wishList.list",
    path: `${APP_PREFIX_PATH}/wishlist/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/wishlist/wishlistList/index")
    ),
  },

  //--------------Review List-------------------------
  {
    key: "review.list",
    path: `${APP_PREFIX_PATH}/review/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/reviews/reviewList/index")
    ),
  },
  {
    key: "review.edit",
    path: `${APP_PREFIX_PATH}/review/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/reviews/reviewEdit/index")
    ),
  },
  {
    key: "review.edit",
    path: `${APP_PREFIX_PATH}/review/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/reviews/reviewDetails/index")
    ),
  },

  // Howstarted List
  // {
  //   key: "consultation.list",
  //   path: `${APP_PREFIX_PATH}/howStarted/list`,
  //   component: React.lazy(() =>
  //     import("views/app-views/apps/howStarted/howStartedList")
  //   ),
  // },
  // started  List
  {
    key: "started.list",
    path: `${APP_PREFIX_PATH}/started/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/started/startedList")
    ),
  },
  {
    key: "started.add",
    path: `${APP_PREFIX_PATH}/started/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/started/startedForm")
    ),
  },
  {
    key: "started.edit",
    path: `${APP_PREFIX_PATH}/started/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/started/startedForm")
    ),
  },
  // professionalqualification  List
  {
    key: "professionalqualification.list",
    path: `${APP_PREFIX_PATH}/professionalqualification/list`,
    component: React.lazy(() =>
      import(
        "views/app-views/apps/professionalQualification/professionalQualificationList"
      )
    ),
  },
  {
    key: "professionalqualification.add",
    path: `${APP_PREFIX_PATH}/professionalqualification/add`,
    component: React.lazy(() =>
      import(
        "views/app-views/apps/professionalQualification/professionalQualificationForm"
      )
    ),
  },
  {
    key: "professionalqualification.edit",
    path: `${APP_PREFIX_PATH}/professionalqualification/edit/:id`,
    component: React.lazy(() =>
      import(
        "views/app-views/apps/professionalQualification/professionalQualificationForm"
      )
    ),
  },
  // why us  List
  {
    key: "whyUs.list",
    path: `${APP_PREFIX_PATH}/whyUs/list`,
    component: React.lazy(() => import("views/app-views/apps/whyUs/whyUsList")),
  },
  {
    key: "whyUs.add",
    path: `${APP_PREFIX_PATH}/whyUs/add`,
    component: React.lazy(() => import("views/app-views/apps/whyUs/whyUsForm")),
  },
  {
    key: "whyUs.edit",
    path: `${APP_PREFIX_PATH}/whyUs/edit/:id`,
    component: React.lazy(() => import("views/app-views/apps/whyUs/whyUsForm")),
  },

  //Email
  {
    key: "email.list",
    path: `${APP_PREFIX_PATH}/email/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/email/emailList/index")
    ),
  },
  {
    key: "email.edit",
    path: `${APP_PREFIX_PATH}/email/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/email/emailAdd/index")
    ),
  },

  // Faq  List

  {
    key: "faq.list",
    path: `${APP_PREFIX_PATH}/faq/list`,
    component: React.lazy(() => import("views/app-views/apps/faq/faqList")),
  },
  {
    key: "faq.add",
    path: `${APP_PREFIX_PATH}/faq/add`,
    component: React.lazy(() => import("views/app-views/apps/faq/faqForm")),
  },
  {
    key: "faq.edit",
    path: `${APP_PREFIX_PATH}/faq/edit/:id`,
    component: React.lazy(() => import("views/app-views/apps/faq/faqForm")),
  },

  //Faq category list
  {
    key: "faqCategory.list",
    path: `${APP_PREFIX_PATH}/faqCategory/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/faq/faqCategoryList")
    ),
  },
  {
    key: "faqCategory.add",
    path: `${APP_PREFIX_PATH}/faqCategory/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/faq/faqCategoryForm")
    ),
  },
  {
    key: "faqCategory.edit",
    path: `${APP_PREFIX_PATH}/faqCategory/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/faq/faqCategoryForm")
    ),
  },
  // Booking Type  List
  {
    key: "bookingType.list",
    path: `${APP_PREFIX_PATH}/bookingType/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/bookingType/bookingTypeList")
    ),
  },
  {
    key: "bookingType.add",
    path: `${APP_PREFIX_PATH}/bookingType/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/bookingType/bookingTypeForm")
    ),
  },
  {
    key: "bookingType.edit",
    path: `${APP_PREFIX_PATH}/bookingType/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/bookingType/bookingTypeForm")
    ),
  },

  //intervention List
  {
    key: "intervention.list",
    path: `${APP_PREFIX_PATH}/intervention/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/intervention/interventionList")
    ),
  },
  {
    key: "intervention.add",
    path: `${APP_PREFIX_PATH}/intervention/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/intervention/interventionForm")
    ),
  },
  {
    key: "intervention.edit",
    path: `${APP_PREFIX_PATH}/intervention/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/intervention/interventionForm")
    ),
  },

  //How It Work List
  {
    key: "howItWork.list",
    path: `${APP_PREFIX_PATH}/howItWork/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/howItWork/howItWorkList")
    ),
  },
  {
    key: "howItWork.add",
    path: `${APP_PREFIX_PATH}/howItWork/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/howItWork/howItWorkForm")
    ),
  },
  {
    key: "howItWork.edit",
    path: `${APP_PREFIX_PATH}/howItWork/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/howItWork/howItWorkForm")
    ),
  },

  //Testimonial List
  {
    key: "testimonial.list",
    path: `${APP_PREFIX_PATH}/testimonial/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/testimonial/testimonialList")
    ),
  },
  {
    key: "testimonial.add",
    path: `${APP_PREFIX_PATH}/testimonial/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/testimonial/testimonialForm")
    ),
  },
  {
    key: "testimonial.edit",
    path: `${APP_PREFIX_PATH}/testimonial/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/testimonial/testimonialForm")
    ),
  },
  //Blogs List
  {
    key: "blogs.list",
    path: `${APP_PREFIX_PATH}/blogs/list`,
    component: React.lazy(() => import("views/app-views/apps/blogs/blogsList")),
  },
  {
    key: "blogs.add",
    path: `${APP_PREFIX_PATH}/blogs/add`,
    component: React.lazy(() => import("views/app-views/apps/blogs/blogsForm")),
  },
  {
    key: "blogs.edit",
    path: `${APP_PREFIX_PATH}/blogs/edit/:id`,
    component: React.lazy(() => import("views/app-views/apps/blogs/blogsForm")),
  },

  //  //extra List
  //  {
  //   key: "extra.list",
  //   path: `${APP_PREFIX_PATH}/extra/list`,
  //   component: React.lazy(() => import("views/app-views/apps/extra/extraList")),
  // },
];
