import { apiRequest } from "utils/async/apiUtils";

// const user_data = localStorage.getItem(AUTH_TOKEN) || null;

export const getProfileDetails = async (setProfile = () => { }) => {
    try {
        const res = await apiRequest(
            'get',
            `/profile/details`
        )
        const data = res.data
        // console.log(data);
        localStorage.setItem("user_data", JSON.stringify({
            id: data.data._id,
            name: `${data.data.fullname.firstName} ${data.data.fullname.lastName}`,
            email: data.data.email,
        }))
        //   setDetails(data.data)
        return data
    } catch (err) {
        // console.log('err', err)
    }
}